@import "src/assets/css/colors.scss";

@charset "UTF-8";

@font-face {
  font-family: "SH Icon";
  font-display: auto;
  src:url("/assets/font/sh_ui/sh_ui_icons_webfont.eot");
  src:url("/assets/font/sh_ui/sh_ui_icons_webfont.eot?#iefix") format("embedded-opentype"),
  url("/assets/font/sh_ui/sh_ui_icons_webfont.woff") format("woff"),
  url("/assets/font/sh_ui/sh_ui_icons_webfont.ttf") format("truetype"),
  url("/assets/font/sh_ui/sh_ui_icons_webfont.svg#sh_ui_icons_webfont") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]::before {
  font-family: "SH Icon", serif !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]::before,
[class*=" icon-"]::before {
  font-family: "SH Icon", serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.icon-arrow-down-large::before, .icon-arrow-down::before { content: '\e81f'; } /* '' */
.icon-arrow-down-medium::before { content: '\e83f'; } /* '' */
.icon-arrow-down-small::before, .icon-dropdown::before { content: '\e803'; } /* '' */
.icon-arrow-drop-down::before { content: '\e81b'; } /* '' */
.icon-arrow-drop-up::before { content: '\e84f'; } /* '' */
.icon-arrow-left-large::before, .icon-arrow-left::before { content: '\e81e'; } /* '' */
.icon-arrow-left-medium::before { content: '\e833'; } /* '' */
.icon-arrow-left-small::before { content: '\e806'; } /* '' */
.icon-arrow-long-left::before { content: '\e842'; } /* '' */
.icon-arrow-long-right::before { content: '\e854'; } /* '' */
.icon-arrow-right-large::before, .icon-arrow-right::before { content: '\e81c'; } /* '' */
.icon-arrow-right-medium::before, .icon-link-intern::before, .icon-intern::before { content: '\e827'; } /* '' */
.icon-arrow-right-small::before { content: '\e80a'; } /* '' */
.icon-arrow-to-top::before { content: '\e84d'; } /* '' */
.icon-arrow-up-large::before, .icon-arrow-top::before { content: '\e81d'; } /* '' */
.icon-arrow-up-medium::before { content: '\e837'; } /* '' */
.icon-arrow-up-small::before { content: '\e840'; } /* '' */
.icon-arrow::before, .icon-extern::before { content: '\e834'; } /* '' */
.icon-at::before { content: '\e83e'; } /* '' */
.icon-basket::before { content: '\e80E'; }
.icon-book::before { content: '\e810'; } /* '' */
.icon-bookmark::before { content: '\e802'; } /* '' */
.icon-bullet-list::before { content: '\e82f'; } /* '' */
.icon-burger::before { content: '\e805'; }
.icon-dots-ellipsis-vertical::before, .icon-more::before  { content: '\e857'; } /* '' */
.icon-calendar::before, .icon-calender::before { content: '\e804'; } /* '' */
.icon-chat::before { content: '\e846'; } /* '' */
.icon-check::before { content: '\e818'; }
.icon-checkmark::before { content: '\e818'; } /* '' */
.icon-close-fullscreen::before { content: '\e839'; } /* '' */
.icon-close::before { content: '\e817'; } /* '' */
.icon-comment::before { content: '\e843'; } /* '' */
.icon-document::before { content: '\e814'; }
.icon-double-arrow-backward::before { content: '\e828'; } /* '' */
.icon-double-arrow-down::before { content: '\e830'; } /* '' */
.icon-double-arrow-forward::before { content: '\e83a'; } /* '' */
.icon-double-arrow-up::before { content: '\e801'; } /* '' */
.icon-download::before { content: '\e823'; } /* '' */
.icon-email::before { content: '\e815'; } /* '' */
.icon-exclamationmark::before { content: '\e836'; }
.icon-facebook::before { content: '\e821'; } /* '' */
.icon-feed::before { content: '\e841'; } /* '' */
.icon-file::before { content: '\e814'; } /* '' */
.icon-fullscreen::before { content: '\e809'; } /* '' */
.icon-globe::before { content: '\e808'; } /* '' */
.icon-google::before { content: '\e829'; } /* '' */
.icon-heart::before { content: '\e824'; } /* '' */
.icon-home::before, .icon-house::before { content: '\e807'; } /* '' */
.icon-information::before, .icon-info::before { content: '\e835'; } /* '' */
.icon-instagram::before { content: '\e852'; } /* '' */
.icon-large-view::before { content: '\e809'; }
.icon-layout-2x2::before { content: '\e819'; } /* '' */
.icon-layout-3x2::before { content: '\e81a'; } /* '' */
.icon-layout-3x3::before { content: '\e851'; } /* '' */
.icon-layout-list::before { content: '\e820'; } /* '' */
.icon-left-right::before { content: '\e838'; } /* '' */
.icon-linkedin::before { content: '\e822'; } /* '' */
.icon-location-pin::before { content: '\e80c'; } /* '' */
.icon-log-in::before { content: '\e831'; } /* '' */
.icon-log-out::before { content: '\e832'; } /* '' */
.icon-menu::before { content: '\e805'; } /* '' */
.icon-minus::before { content: '\e82e'; } /* '' */
.icon-pause::before { content: '\e825'; } /* '' */
.icon-phone::before, .icon-tel::before { content: '\e80b'; } /* '' */
.icon-pin::before { content: '\e80C'; }
.icon-play::before { content: '\e80d'; } /* '' */
.icon-plus::before { content: '\e82d'; } /* '' */
.icon-print::before { content: '\e816'; } /* '' */
.icon-rating::before { content: '\e812'; } /* '' */
.icon-reload::before { content: '\e83b'; } /* '' */
.icon-replay::before { content: '\e853'; } /* '' */
.icon-scale-down::before { content: '\e83c'; } /* '' */
.icon-scale-up::before { content: '\e83d'; } /* '' */
.icon-search::before { content: '\e80f'; } /* '' */
.icon-share::before { content: '\e811'; } /* '' */
.icon-shopping-cart::before { content: '\e80e'; } /* '' */
.icon-slider-drag-drop::before, .icon-slider::before { content: '\e260'; } /* '' */
.icon-snippet::before { content: '\e85c'; } /* '' */
.icon-speakers-off::before, .icon-volume-muted::before, .icon-volume-off::before { content: '\e82a'; } /* '' */
.icon-speakers-on::before, .icon-volume-up::before, .icon-volume-down::before { content: '\e82b'; } /* '' */
.icon-star::before { content: '\e812'; }
.icon-text-check::before { content: '\e844'; } /* '' */
.icon-tool::before, .icon-settings::before, .icon-filter::before { content: '\e845'; } /* '' */
.icon-twitter::before, .icon-x::before { content: '\e813'; } /* '' */
.icon-upload::before { content: '\e82c'; } /* '' */
.icon-user::before { content: '\e800'; } /* '' */
.icon-video-hd::before { content: '\e848'; } /* '' */
.icon-video-skip-backward::before { content: '\e849'; } /* '' */
.icon-video-skip-forward::before { content: '\e84a'; } /* '' */
.icon-video-stop::before { content: '\e84b'; } /* '' */
.icon-view-carousel::before { content: '\e84c'; } /* '' */
.icon-warning::before { content: '\e836'; } /* '' */
.icon-world::before, .icon-globe::before { content: '\e808'; }
.icon-youtube::before { content: '\e84e'; } /* '' */
.icon-bell:before { content: '\e855'; }
.icon-filter:before { content: '\e858'; }
.icon-settings:before {content: '\e85f';}
.icon-moon:before {content: '\e859'; }
.icon-sun:before {  content: '\e85e'; }
.icon-nine-dots:before {  content: '\e85a'; }
.icon-dots-ellipsis-vertical:before {  content: '\e857'; }
.icon-dots-ellipsis-horizontal:before {  content: '\e856'; }
.icon-smiley-positive:before {  content: '\e85d'; }
.icon-smiley-neutral:before {  content: '\e826'; }
.icon-smiley-negative:before {  content: '\e85b'; }
.icon-360-grad::before { content: '\e847'; } /* '' */

.icon-check__circle {
  border-radius: 50%;
  background-color: $green;
  padding: 4px 6px 4px 6px;
  font-size: 0.5rem;
  vertical-align: middle;
  color: $white;
}

.icon-close__circle {
  border-radius: 50%;
  background-color: $red;
  padding: 4px 6px;
  font-size: 0.5rem;
  vertical-align: middle;
  color: $white;
}

.icon-warning__circle {
  border-radius: 50%;
  background-color: $yellow;
  padding: 4px 6px;
  font-size: 0.5rem;
  vertical-align: middle;
  color: #000000;
}

.icon-exclamationmark__circle {
  border-radius: 50%;
  background-color: $red;
  padding: 4px 6px;
  font-size: 0.5rem;
  vertical-align: middle;
  color: $white;
}

.icon-info__circle {
  border-radius: 50%;
  background-color: $black;
  padding: 4px 6px;
  font-size: 0.5rem;
  vertical-align: middle;
  color: $white;
}

.icon-orange-white {
  background-color: $orange;
  color: $white;
}

.icon-dot {
  &:before {
    content: '';
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $orange;
  }
}
