.width-5 {
  width: 5%;
}
.width-10 {
  width: 10%;
}
.width-15 {
  width: 15%;
}
.width-20 {
  width: 20%;
}
.width-25 {
  width: 25%;
}
.width-30 {
  width: 30%;
}
.width-35 {
  width: 35%;
}
.width-40 {
  width: 40%;
}
.width-45 {
  width: 45%;
}
.width-50 {
  width: 50%;
}
.width-60 {
  width: 60%;
}
.width-65 {
  width: 65%;
}
.width-70 {
  width: 70%;
}
.width-75 {
  width: 75%;
}
.width-80 {
  width: 80%;
}
