.padding-right-01 {
  padding-right: 0.1rem;
}
.padding-right-02 {
  padding-right: 0.2rem;
}
.padding-right-03 {
  padding-right: 0.3rem;
}
.padding-right-04 {
  padding-right: 0.4rem;
}
.padding-right-05 {
  padding-right: 0.5rem;
}
