@import "variables/menu-variables";

.menu__item {
  transition: background-color 0.1s ease-out;
}

.dropdown .divider {
  height: $separator-size;
  overflow: hidden;
  background-color: $separator-gray-tint;
  margin: $separator-margin;
  display: block;
}

.meta-menu__link {
  .dropdown__toggle::before {
    color: #666666;
    font-size: 1.6em;
    position: relative;
    top: 2px;
    margin-right: inherit;
  }
}
