/* SH-Bree-Headline Basic Latin Glyphs */
@font-face {
  font-family: "SH Headline";
  font-display: auto;
  src: local('SH-Bree-Headline'),
  url("/assets/font/SH-Bree-Headline/SH-Bree-Headline_LATIN.woff2") format("woff2"),
  url("/assets/font/SH-Bree-Headline/SH-Bree-Headline_LATIN.woff") format("woff"),
  url("/assets/font/SH-Bree-Headline/SH-Bree-Headline_LATIN.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+020-21b, U+1e0c-203a; /* Latin glyphs */
}

@font-face {
  font-family: "SH Headline";
  src: /* local TTF only available as regular */
          url("/assets/font/SH-Bree-Headline/SH-Bree-Headline_LATIN.woff2") format("woff2"),
          url("/assets/font/SH-Bree-Headline/SH-Bree-Headline_LATIN.woff") format("woff"),
          url("/assets/font/SH-Bree-Headline/SH-Bree-Headline_LATIN.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  unicode-range: U+020-21b, U+1e0c-203a; /* Latin glyphs */
}

/* Siemens Sans */
@font-face {
  font-family: "Siemens Sans";
  font-display: auto;
  src: local('Siemens Sans'),
  local('SiemensSans-Roman'),
  url("/assets/font/SiemensSans/SiemensSans-Roman.woff2") format("woff2"),
  url("/assets/font/SiemensSans/SiemensSans-Roman.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Siemens Sans";
  font-display: auto;
  src: local('Siemens Sans Italic'),
  local('SiemensSans-Italic'),
  url("/assets/font/SiemensSans/SiemensSans-Italic.woff2") format("woff2"),
  url("/assets/font/SiemensSans/SiemensSans-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Siemens Sans";
  font-display: auto;
  src: local('Siemens Sans Black'),
  local('SiemensSans-Black'),
  url("/assets/font/SiemensSans/SiemensSans-Black.woff2") format("woff2"),
  url("/assets/font/SiemensSans/SiemensSans-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Siemens Sans";
  src: local('Siemens Sans Bold'),
  local('SiemensSans-Bold'),
  url("/assets/font/SiemensSans/SiemensSans-Bold.woff2") format("woff2"),
  url("/assets/font/SiemensSans/SiemensSans-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

.text-capitalize {
  text-transform: capitalize;
}

.font-weight-600 {
  font-weight: 600;
}
