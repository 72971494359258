/* You can add global styles to this file, and also import other style files */
@import "src/assets/css/dimensions";
@import "src/assets/css/display";
@import "src/assets/css/font";
@import "src/assets/css/icons";
@import "src/assets/css/img";
@import "src/assets/css/loader";
@import "src/assets/css/padding";
@import "src/assets/css/stripe";
@import "src/assets/css/menu";
@import "src/assets/css/datepicker";
@import "src/assets/css/slick";
@import "slick-carousel/slick/slick";
@import "node_modules/@shs/ui-marcom/src/scss/ui-marcom.scss";
@import "ui-marcom";

@include sh-accordion();
@include sh-application-bar();
@include sh-meta-menu();
@include sh-menu();
@include sh-dropdown();
@include sh-bare-list();
@include sh-user-identifier();
@include sh-search-field();
@include sh-textfield();
@include sh-navigation-bar();
@include sh-selectbox();
@include sh-icon();
@include sh-button();
@include sh-logo($options: (file-path: '/assets/img', file-name: 'logo.svg'));
@include sh-grid();
@include sh-legal-links();
@include sh-footer();
@include sh-pagination();
@include sh-tooltip();
@include sh-alert();
@include sh-aspect-ratio();
@include sh-card();
@include sh-carousel();
@include sh-card-slider();
@include sh-tabs();
@include sh-steps-bar();
@include sh-progress();
@include sh-block-grid();
@include sh-breadcrumbs();
@include sh-media-object();
@include sh-multi-column();
@include sh-u-alignments();
@include sh-u-background-colors();
@include sh-u-background-images();
@include sh-u-borders();
@include sh-u-border-radius();
@include sh-u-colors();
@include sh-u-dimensions();
@include sh-u-displays();
@include sh-u-flex();
@include sh-u-floatings();
@include sh-u-font-families();
@include sh-u-font-sizes();
@include sh-u-font-styles();
@include sh-u-font-weights();
@include sh-u-image-replacements();
@include sh-u-image-sizes();
@include sh-u-margin();
@include sh-u-padding();
@include sh-u-positionings();
@include sh-u-text-decorations();
@include sh-u-visibilities();
@include sh-checkbox();
@include sh-button-group();
@include sh-hero-block();
@include sh-overlay();
@include sh-label();
@include sh-radio();
@include sh-date-time-picker();
@include sh-modal-dialog();
@include sh-textarea();
@include sh-loading-animation();
@include sh-steps-bar();
@include sh-back-to-top();

@import "src/assets/css/marcom-override.scss";

.search-field {
  .icon-search {
    height: auto !important;
  }
}
