.flatpickr-calendar {
  z-index: 10 !important;

  .flatpickr-weekday {
    cursor: default !important;
  }

  .flatpickr-disabled {
    color: #cecece !important;
    cursor: default !important;
  }

  .flatpickr-disabled:hover::before {
    background-color: white !important;
  }
}
