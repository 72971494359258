.button--link {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  transition: none;
  cursor: pointer;
}

.button--link:hover, .button--link:active {
  color: #cf4b00;
  background: transparent !important;
}

.alert {
  background-color: #fff;
  box-shadow: 0 4px 8px #0000001a, 0 -1px 4px #0000000d;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all .16s ease-out;
  max-height: 100vh;
  overflow: visible;
  min-width: 1px;
  margin: auto;
  padding: 0;
}

.alert__container {
  padding: 1.1111111111rem;
}

.alert__close {
  font-size: 1.2222222rem;
  float: right;
  margin-bottom: 1.1111111111rem;
  margin-left: 1.1111111111rem;
  min-width: auto;
  padding: 0;
  line-height: 1;
}
