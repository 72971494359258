.v-align-middle {
  vertical-align: middle;
}

.f-align-items-center {
  align-items: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.space-between {
  justify-content: flex-start;
}

.clear-both {
  clear: both;
}
