$white:    #ffffff;
$black-5:  #f2f2f2;
$black-6:  #f9f9f9;
$black-7:  #ededed;
$black-10: #e6e6e6;
$black-20: #cccccc;
$black-25: #bfbfbf;
$black-30: #b3b3b3;
$black-40: #999999;
$black-50: #808080;
$gray:     #666666;
$black-60: #5f5f5f;
$black-70: #4d4d4d;
$black-75: #404040;
$black-80: #333333;
$black-90: #1a1a1a;
$black:    #000000;

// Primary colors

$orange-shade-1: #cf4b00;
$orange:         #ec6602;
$orange-tint-2:  #f9b591;
$orange-tint-3:  #fdddcb;
$orange-icon-1:  #ec6825;

$petrol-shade-1: #02787d;
$petrol:         #009999;
$petrol-tint-2:  #87d2d2;
$petrol-tint-3:  #c8e6e6;

// Functional colors

$red-shade-1:   #d9001d;
$red:           #e7001d;
$red-tint-2:    #f3808e;
$red-tint-3:    #f9bfc7;

$green-shade-1: #197c3a;
$green:         #009a38;
$green-tint-2:  #80cd9c;
$green-tint-3:  #bfe6cd;

$yellow:        #ffd200;
$yellow-tint-2: #ffe980;
$yellow-tint-3: #fff3bf;

// Additional color

$cyan:        #3abfed;
$cyan-tint-2: #9ddff6;
$cyan-tint-3: #ceeffb;

$brown: #cf4b0066;

$berry:       #7a162d;
$berry-50:    #c69b9e;
$berry-25:    #e9d1d4;

$blue:        #2b2483;
$blue-50:     #9592c1;
$blue-25:     #cac8e0;
