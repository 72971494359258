.loader__overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9000;

  .loader__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.loading__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loader__fixed {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9000;
}
