$color-base: #d6d6d6;
$color-highlight: lighten($color-base, 7%);

.stripe {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 1rem;
  height: 100%;
  background: $color-base !important;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, $color-base, $color-highlight, $color-base);
    animation: progress 1s ease-in-out infinite;
  }

  &.stripe-short {
    width: 25%;
  }

  &.stripe-half {
    width: 50%;
  }

  &.stripe-long {
    width: 75%;
  }

  &.stripe-tall {
    min-height: 1.5rem;
  }
}

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
